

































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {Instructor, InstructorSalesModel} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

@Component({})
export default class InstructorSales extends Vue {

    @Prop({
        default: () => {
            return new Instructor()
        }
    })

    value!: Instructor

    statusStr = {
        2: 'Enrolled',
        1: 'Enrolled',
        0: 'Pending',
        [-1]: 'Cancelled',
        [-2]: 'Refunded',
    }

    selectClass = {
        2: 'select-enrolled',
        1: 'select-enrolled',
        0: 'select-pending',
        [-1]: 'select-cancelled',
        [-2]: 'select-refund',
    }

    // sales data class
    data: InstructorSalesModel = new InstructorSalesModel()

    selectedMonth: string = ''
    selectedMonth2: string = ''

    @Emit()
    input(value: Instructor) {
        //
    }

    get localValue(): Instructor {
        return this.value
    }

    set localValue(value: Instructor) {
        this.input(value)
    }

    created() {
        this.selectedMonth2 = new Date().getMonth() + 1 + '/' + new Date().getFullYear()
        this.getInstructorSalesReport()
    }

    getInstructorSalesReport() {
        const param: { [key: string]: string } = {
            month: this.selectedMonth
        }
        createRequest('/instructor/' + this.$route.params['_id'] + '/order-report', 'get', param).send()
            .then((res) => {
                this.data = res.data.report
            })
    }

    changeDay(value: Date) {
        this.selectedMonth = value.getMonth() + 1 + '/' + value.getFullYear()
        this.getInstructorSalesReport()
    }

}
