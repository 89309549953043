











import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {},
    computed: {}
})
export default class ChatMonster extends Vue {
    apiToken: string = ''
    phone: string = '852'

    async created() {
        await this.getData()
    }

    async getData() {
        const res = await createRequest(`/instructor/${this.$route.params['_id']}/chatmonster`, 'get').send()
        this.apiToken = res.data.api_token || ''
        this.phone = res.data.phone || ''
    }

    async submit() {
        if (!this.phone.startsWith('852'))
            this.phone = '852' + this.phone
        const update: any = {api_token: this.apiToken, phone: this.phone}

        await createRequest(`/instructor/${this.$route.params['_id']}/chatmonster`, 'patch', {}, update).send()
        this.$message({message: 'Saved', type: 'success'})
    }
}
