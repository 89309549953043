












































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {Course, Instructor} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

@Component({})
export default class InstructorCourses extends Vue {
    @Prop({
        default: () => {
            return new Instructor()
        }
    })
    value!: Instructor

    // current instructor courses
    tableData: Course[] = []

    // page
    currentPage: number = 1
    totalPage: number = 0
    step = 20

    // course status
    statusOptions: Array<{ value: number, label: string }> = [
        {
            value: 0,
            label: 'Pending'
        }, {
            value: 1,
            label: 'Published'
        }, {
            value: 2,
            label: 'Hidden'
        },
    ]

    selectClass = {
        0: 'select-pending',
        1: 'select-published',
        2: 'select-hidden',
    }

    @Emit()
    input(value: Instructor) {
        //
    }

    get localValue(): Instructor {
        return this.value
    }

    set localValue(value: Instructor) {
        this.input(value)
    }

    created() {
        this.getInstructorCourse()
    }

    handleCurrentChange(pageNum) {
        this.currentPage = pageNum
        this.getInstructorCourse()
    }

    getInstructorCourse() {
        const param: { [key: string]: string } = {
            instructor: this.value._id + '',
            skip: (this.currentPage - 1) * this.step + '',
            number: this.step + ''
        }
        createRequest('/courses/admin', 'get', param).send()
            .then((res) => {
                this.tableData = res.data.courses
                this.totalPage = Math.ceil(res.data.count / this.step)
            })
    }

}
