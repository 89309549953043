









































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'

import InstructorPersonal from './instructor/instructor-personal.vue'
import InstructorCourses from './instructor/instructor-courses.vue'
import InstructorSales from './instructor/instructor-sales.vue'
import InstructorPaymentHistory from './instructor/instructor-payment-history.vue'
import ChatMonster from './instructor/chatmonster.vue'
import InstructorsData from '~/components/instructor/instructors-data'
import {AdminPermission} from '~/utils/permissions'
import {InstructorAdminEdit} from '@afterschool.dev/as-data-admin'
import {InstructorDatabase} from '@afterschool.dev/as-data-admin/lib/instructor/instructor.admin'

@Component({
    components: {
        InstructorPersonal,
        InstructorCourses,
        InstructorSales,
        InstructorPaymentHistory,
        ChatMonster
    },
    metaInfo() {
        return {
            title: 'Tutor Detail'
        }
    }
})
export default class InstructorView extends Vue {
    static permission = AdminPermission.Instructor

    // nav bar
    activeIndex: string = '0'

    instructor = new InstructorAdminEdit()

    orgInstructor = new InstructorDatabase()

    disableSave = false

    handleNavChange(key, keyPath) {
        this.activeIndex = key
        switch (key) {
            case '0':
                this.$router.push({query: {tab: 'Personal'}})
                break
            case '1':
                this.$router.push({query: {tab: 'Courses'}})
                break
            case '2':
                this.$router.push({query: {tab: 'Sales'}})
                break
            case '3':
                this.$router.push({query: {tab: 'Payment'}})
                break
            default:
                break
        }
    }

    created() {
        this.updateInstructor()

        // nav bar
        const tab = this.$route.query['tab'] as string
        const dict = {
            'Personal': '0',
            'Courses': '1',
            'Sales': '2',
            'Payment': '3',
        }
        this.activeIndex = dict[tab] || '0'
    }

    updateInstructor() {
        createRequest('/instructor/' + this.$route.params['_id'] + '/profile', 'get').send()
            .then((res) => {
                this.instructor = InstructorAdminEdit.from(res.data.instructor)
                this.orgInstructor = res.data.instructor
            }).catch((e) => {
            console.error(e)
        })
    }

    async onSubmit() {
        if (this.activeIndex === '0') {
            await createRequest('/instructor/' + this.orgInstructor.member_id + '/profile', 'patch', {}, this.instructor).send()

            await InstructorsData.update()
            this.$message({
                message: `Saved!`,
                type: 'success'
            })
        }
    }

    changedField(): string {
        let changed: string = ''

        // if (this.instructor.display_name != this.orgInstructor.display_name) {
        //     changed += 'display name, '
        // }

        if (this.instructor.url_name != this.orgInstructor.url_name) {
            changed += 'url name, '
        }

        if (this.instructor.tagline != this.orgInstructor.tagline) {
            changed += 'tagline, '
        }

        if (this.instructor.subjects.length != this.orgInstructor.subjects.length) {
            changed += 'subject, '
        } else {
            if (JSON.stringify(this.instructor.subjects) !== JSON.stringify(this.orgInstructor.subjects)) {
                changed += 'subject, '
            }
        }

        if (this.instructor.description != this.orgInstructor.description) {
            changed += 'description, '
        }

        if (this.instructor.bank != this.orgInstructor.bank) {
            changed += 'bank, '
        }

        if (this.instructor.bank_account != this.orgInstructor.bank_account) {
            changed += 'bank account, '
        }

        if (this.instructor.bank_account_holder != this.orgInstructor.bank_account_holder) {
            changed += 'bank holder, '
        }

        if (this.instructor.split_value != this.orgInstructor.split_value) {
            changed += 'split value, '
        }

        if (this.instructor.referral_split_value != this.orgInstructor.referral_split_value) {
            changed += 'referral split value, '
        }

        if (this.instructor.show != this.orgInstructor.show) {
            changed += 'show, '
        }

        if (this.instructor.video_id != this.orgInstructor.video_id) {
            changed += 'video, '
        }

        return changed
    }

}

