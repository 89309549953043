








import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {Instructor} from '~/components/data-class/data-class'

@Component({})
export default class InstructorPaymentHistory extends Vue {
    @Prop({
        default: () => {
            return new Instructor()
        }
    })
    value!: Instructor

    @Emit()
    input(value: Instructor) {
        //
    }

    get localValue(): Instructor {
        return this.value
    }

    set localValue(value: Instructor) {
        this.input(value)
    }
}
