

































































































































































































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {Instructor} from '~/components/data-class/data-class'
import {courseSubjects} from '@afterschool.dev/as-data-admin'
import ElSelectDropdown from 'element-ui/packages/select/src/select-dropdown.vue'
import InstructorPopover from '~/components/popover/instructor-popover.vue'
import UrlSlugInput from '~/components/url-slug-input.vue'
import {createRequest} from '~/utils/network-request'
import {userPropicURL} from '~/utils/misc'
import {InstructorAdmin, InstructorAdminEdit} from "@afterschool.dev/as-data-admin";

@Component({
    components: {
        ElSelectDropdown,
        InstructorPopover,
        UrlSlugInput
    }
})
export default class InstructorPersonal extends Vue {
    @Prop({default: () => new InstructorAdmin()}) originalInstructor: InstructorAdmin

    @Prop({default: () => new InstructorAdminEdit()})
    value!: InstructorAdminEdit

    baseSubjects: Array<{ value: string, label: string }> = []

    providePaymentMethod: Array<{ value: string, label: string }> = [
        {
            label: 'Cash',
            value: 'cash'
        }, {
            label: 'Bank transfer',
            value: 'bank transfer'
        }
    ]

    urlNameUsed = false

    urlNameUsedDoc: Instructor | null = null

    get splitValue() {
        return (this.value.split_value * 100 + '')
    }

    set splitValue(value: string) {
        this.value.split_value = Number(value) / 100
    }

    get referralSplitValue() {
        return (this.value.referral_split_value * 100 + '')
    }

    set referralSplitValue(value: string) {
        this.value.referral_split_value = Number(value) / 100
    }

    get sleekflowNumber() {
        return this.value.sleekflow_number.replace('852', '')
    }

    set sleekflowNumber(value: string) {
        if (value === '') {
            this.value.sleekflow_number = ''
        }
        else {
            this.value.sleekflow_number = '852' + value
        }
    }

    @Watch('urlNameUsed')
    @Emit('update:disable-save')
    urlNameUsedChanged() {}

    @Emit()
    input(value: InstructorAdminEdit) {
        //
    }

    get localValue(): InstructorAdminEdit {
        return this.value
    }

    set localValue(value: InstructorAdminEdit) {
        this.splitValue = value.split_value * 100 + ''
        this.referralSplitValue = value.referral_split_value * 100 + ''
        this.input(value)
    }

    created() {
        courseSubjects.forEach((subject) => {
            this.baseSubjects.push({
                value: subject,
                label: subject
            })
        })
    }

    propicURL(memberId: string) {
        return userPropicURL(memberId)
    }

    vidInput(v: string) {
        if(v.includes('/')) {
            const parts = v.split('/')
            this.value.video_id = parts[parts.length-1]
        }
    }
    async generateStreamKey() {
        if(!this.originalInstructor.url_name) {
            alert('Please set and save URL Name first')
            return
        }

        await createRequest('/instructor/' + this.originalInstructor.member_id + '/stream-key', 'post').send()
        this.$emit('updateInstructor')
    }
}
