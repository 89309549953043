





























import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {VTextField} from 'vuetify/lib'

@Component({
    components: {
        VTextField
    }
})
export default class UrlSlugInput extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value!: string

    @Prop({
        default: () => {
            return "Url Slug"
        }
    })
    placeholder: string

    @Prop({
        default: () => {
            return false
        }
    })
    clearable: boolean

    @Prop()
    type: string

    @Prop({
        default: () => {
            return false
        }
    })
    used: boolean

    @Prop()
    hint: string

    @Prop({ default: false })
    persistentHint: boolean

    @Prop()
    color: string

    @Prop({
        default: () => {
            return null
        }
    })
    _id: string | number | null
    async created() {
        await this.checkUsed()
    }

    @Watch('value')
    async checkUsed() {
        if (!this.type || !this.value)
            return
        const res = await createRequest(`/url-slug/${this.type}/used`, 'post', {},
            {url_slug: this.value, _id: (this._id === '') ? null : this._id}).send()
        this.$emit('update:used', res.data.used)
        this.$emit('update:doc', res.data.doc)
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
